import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: 500,
    },
});

const Block = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                <Typography variant="h5" gutterBottom>
                    You are blocked. Contact to admin
                </Typography>
            </div>
        </div>
    );
}

export default Block;