import React, { useState, useContext } from 'react';
import { Grid, Button, TextField, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { AuthContext } from '../contexts/AuthProvider';
import firebaseDb from '../config/Firebase';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    timesheetInput: {
        width: '100%',
    },
    root: {
        width: '100%',
        maxWidth: 500,
    },
    button: {
        marginLeft: 50,
        marginTop: 10
    }
}))
const EditProfile = () => {
    const classes = useStyles();
    const { auth } = useContext(AuthContext);
    const [name, setName] = useState(auth.name);
    const history = useHistory()
    const [update, setUpdate] = useState(true);
    const save = () => {
        auth.name = name;
        firebaseDb.child(`User/${auth.googleId}`).set(
            auth,
            err => {
                if (err)
                    console.log(err)
            })

            history.push('/');

    }

    const handleUsername = (event) => {
        setName(event.target.value);
        setUpdate(!update);
    }
    console.log(name);
    return (
        <>
            <div>
                <Typography variant="h5" gutterBottom>
                    Edit Profile
            </Typography>
            </div>
            <Grid container>
                <Grid item xs={3}>
                    <TextField
                        className={classes.timesheetInput}
                        label="Full Name"
                        value={name}
                        onChange={(event) => { handleUsername(event) }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={save}
                    >Save </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default EditProfile;