import React from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  timesheetInput: {
    width: '100%',
  }
}))

const TimesheetEntry = (properties) => {
  const classes = useStyles();
  const { 
		period, 
		classesList,
		handleChangeClass,
		handleChangeSubject,
		handleChangeWorkdata,
		row
	} = properties;
  return (
    <>
    <Grid container spacing={2} justify="center" alignItems="center">
		<Grid item xs={1}>
			{row}
			</Grid>
			<Grid item xs={3}>
        <InputLabel id="demo-simple-select-label">Class</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={period.class}
          onChange={(event) => { handleChangeClass(event, period)}}
          className={classes.timesheetInput}
        >
          {classesList.map((classEntry) => {
            return (
              <MenuItem value={classEntry.name}>{classEntry.name}</MenuItem>
            )
          })}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <InputLabel id="demo-simple-select-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={period.subject}
          onChange={(event) => { handleChangeSubject(event, period)}}
          className={classes.timesheetInput}
        >
          {classesList.map((classEntry) => {
            if(classEntry.name==period.class){
              return(
              classEntry.subjectList.map((subject)=>{
                return (
                  <MenuItem value={subject}>{subject}</MenuItem>
                )
              })
              )
            }
            
          })}
        </Select>
      </Grid>
      <Grid item xs={5}>
        <TextField 
          className={classes.timesheetInput} 
          label="Work Detail" 
          value={period.workDetail} 
          onChange={(event) => { handleChangeWorkdata(event, period)}}/>
      </Grid>
    </Grid>



    </>

  )
}

export default TimesheetEntry;