import Login from './views/Login'
import Dashboard from './layout/Dashboard'
import {
	BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import AuthProvider from './contexts/AuthProvider'
import './App.css'
import ClassMasterData from './views/ClassMasterData';
import AddTimesheet from './views/AddTimesheet';
import Report from './views/Reports';
import Users from './views/user';
import Block from './views/Block';
import EditProfile from './views/EditProfile';

function App() {
	return (
		<AuthProvider>
    <Router>
      <div>
        <Switch>
          <Route path="/dashboard">
						<Dashboard>
              <AddTimesheet />
            </Dashboard>
          </Route>
          <Route path="/class">
          <Dashboard>
            <ClassMasterData />
          </Dashboard>
          </Route>
          <Route path="/report">
          <Dashboard>
            <Report />
          </Dashboard>
          </Route>
          <Route path="/users">
          <Dashboard>
            <Users />
          </Dashboard>
          </Route>
          <Route path="/editProfile">
          <Dashboard>
            <EditProfile />
          </Dashboard>
          </Route>
          <Route path="/block">
          <Dashboard>
            <Block />
          </Dashboard>
          </Route>
          <Route path="/">
						<Login></Login>
          </Route>
        </Switch>
      </div>
    </Router>
		</AuthProvider>
  );
}

export default App;
