export const DateFinder = (() => {
    let today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (mm <= 9 && dd > 9) {
        today = yyyy + '-0' + mm + '-' + dd;
    } else if (mm <= 9 && dd <= 9) {
        today = yyyy + '-0' + mm + '-0' + dd;
    } else if (mm > 9 && dd <= 9) {
        today = yyyy + '-' + mm + '-0' + dd;
    } else {
        today = yyyy + '-' + mm + '-' + dd;
    }

    return today;
})