import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DateFinder } from '../../utils/dateFinder';
import firebaseDb from '../../config/Firebase';
const useStyles = makeStyles((theme) => ({
    timesheetInput: {
        width: '80%',
    },

}))
const Report3 = ((properties) => {
    const classes = useStyles();
    const today = DateFinder()
    const [timeSheet, setTimeSheet] = useState();
    const [reportdate, setreportdate] = useState(today);
    const [reportteacher, setreportteacher] = useState('');
    const [keys, setKeys] = useState();
    const [update, setUpdate] = useState(true);

    const handleChangereportdate = ((event) => {
        setreportdate(event.target.value);
        setUpdate(!update);
    })
    const handleChangereportteacher = ((event) => {
        setreportteacher(event.target.value);
        setUpdate(!update);
    })

    useEffect(() => {
        firebaseDb.child(`Timesheet/${reportdate}`).on('value', snapshot => {
            if (snapshot.val() != null) {
                const allTimesheets = snapshot.val()
                const keys = Object.keys(allTimesheets);
                setTimeSheet(allTimesheets);
                setKeys(keys);
            }else{
                setTimeSheet();
                setKeys(keys);
            }
        })
    }, [reportdate])

    console.log(timeSheet);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        id="date"
                        label="Select Date"
                        type="date"
                        // defaultValue={today}
                        value={reportdate}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => { handleChangereportdate(event) }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="demo-simple-select-label">Teachers</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reportteacher}
                        onChange={(event) => { handleChangereportteacher(event) }}
                        className={classes.timesheetInput}
                    >
                        {timeSheet ?
                            keys.map((teacherData) => {
                                return (
                                    <MenuItem value={timeSheet[teacherData].name}>{timeSheet[teacherData].name}</MenuItem>
                                )
                            })
                            :
                            <>
                            </>
                        }
                    </Select>
                </Grid>
            </Grid>
            <TableContainer className={classes.table} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Class</TableCell>
                            <TableCell>Subject</TableCell>
                            <TableCell>Work</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timeSheet ?
                            keys.map((teacher) => {
                                if (reportteacher == timeSheet[teacher].name) {
                                    return (
                                        <>
                                            {timeSheet[teacher].periods.map((data) => {
                                                if(data.class!="" && data.subject!="" && data.workDetail!=""){
                                                return (
                                                    <TableRow>
                                                        <TableCell>{data.class}</TableCell>
                                                        <TableCell>{data.subject}</TableCell>
                                                        <TableCell>{data.workDetail}</TableCell>
                                                    </TableRow>
                                                )
                                                }
                                            })}
                                        </>
                                    )
                                }
                            })
                            :
                            <></>
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </>
    );
})

export default Report3;