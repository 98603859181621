import React, {useContext} from 'react';
import { useGoogleLogout } from 'react-google-login';
import ListItemText from '@material-ui/core/ListItemText';
import { AuthContext } from '../contexts/AuthProvider';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const clientId =
	'289619533297-p0dojgd41bm6fc77hl3ue7tj3rgkn11u.apps.googleusercontent.com';

function LogoutHooks() {
	const { login } = useContext(AuthContext);
	const history = useHistory();

  const onLogoutSuccess = (res) => {
		login(null)
		history.push('/')
  };

  const onFailure = () => {
    console.log('Handle failure cases');
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
		<ListItem button onClick={signOut} >
		<ListItemIcon>
			<ExitToAppIcon />
		</ListItemIcon>
		<ListItemText primary="Sign Out" />
    </ListItem>

	)
}

export default LogoutHooks;