import firebase from 'firebase/app'
import 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyDwPraJhnVIwUcvfyaEGpGl5rb80CWBF2Q",
  authDomain: "igm-hrms.firebaseapp.com",
  databaseURL: "https://igm-hrms-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "igm-hrms",
  storageBucket: "igm-hrms.appspot.com",
  messagingSenderId: "289619533297",
  appId: "1:289619533297:web:dab7a93dba2978ee1a7850",
  measurementId: "G-T04335X122"
};
var fireDb = firebase.initializeApp(firebaseConfig);  
  
export default fireDb.database().ref();  