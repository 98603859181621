import React, { useEffect, useState } from 'react';
import firebaseDb from '../config/Firebase';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid, Button, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: 50
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: 120,
    },
    gridColumn: {
        flexDirection: 'column'
    },
    timesheetInput: {
        width: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    button: {
        marginLeft: 50,
        marginTop: 10
    }
}));

export default function Users() {
    const classes = useStyles();
    const [users, setUsers] = useState();
    const [update, setUpdate] = useState(true);
    const [keys, setKeys] = useState([]);
    const history = useHistory();

    useEffect(() => {
        firebaseDb.child(`User`).on('value', snapshot => {
            if (snapshot.val() != null) {
                // value = snapshot.val();
                setUsers(
                    snapshot.val()
                );
                const keys = Object.keys(snapshot.val());
                setKeys(keys)
                setUpdate(!update);
            }
        })

    }, [])
    const save = (() => {
        firebaseDb.child(`User`).set(
            users,
            err => {
                if (err)
                    console.log(err)
            })
            history.push('/')
    })
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>UserName</TableCell>
                            <TableCell>isAdmin</TableCell>
                            <TableCell>isBlock</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {keys.map((key) => (
                            <TableRow key={users[key].name}>
                                <TableCell component="th" scope="row">
                                    {users[key].name}
                                </TableCell>
                                <TableCell>
                                    {users[key].isAdmin == true ?

                                        <Checkbox
                                            checked
                                            color="default"
                                            onChange={
                                                () => {
                                                    users[key].isAdmin = false;
                                                    setUpdate(!update);
                                                }
                                            }
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />

                                        :

                                        <Checkbox
                                            color="default"
                                            onChange={
                                                () => {
                                                    users[key].isAdmin = true;
                                                    setUpdate(!update);
                                                }
                                            }
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />

                                    }
                                </TableCell>
                                <TableCell>
                                    {users[key].isBlock == true ?

                                        <Checkbox
                                            checked
                                            color="default"
                                            onChange={
                                                () => {
                                                    users[key].isBlock = false;
                                                    setUpdate(!update);
                                                }
                                            }
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />

                                        :

                                        <Checkbox
                                            color="default"
                                            onChange={
                                                () => {
                                                    users[key].isBlock = true;
                                                    setUpdate(!update);
                                                }
                                            }
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />

                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={save}
                >Save </Button>
            </Grid>
        </>
    );
}
