import React, { useState, useEffect, useContext } from 'react';
import firebaseDb from '../config/Firebase';
import { Grid, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { AuthContext } from '../contexts/AuthProvider';
import TimesheetEntry from '../components/atoms/TimesheetEntry';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DateFinder } from '../utils/dateFinder';
const formattedDate = DateFinder();
const AddTimesheet = () => {
	const { auth } = useContext(AuthContext);
	const [timesheet, setTimesheet] = useState({
		name: auth?.name,
		email: auth?.email,
		periods: [
			{
				workDetail: '',
				class: '',
				subject: ''
			},
			{
				workDetail: '',
				class: '',
				subject: ''
			},
			{
				workDetail: '',
				class: '',
				subject: ''
			},
			{
				workDetail: '',
				class: '',
				subject: ''
			},
			{
				workDetail: '',
				class: '',
				subject: ''
			},
			{
				workDetail: '',
				class: '',
				subject: ''
			}
		]
	});
	const [update, setUpdate] = useState(true)
	const [classesList, setClassesList] = useState([]);
	const [openAlert, setOpenAlert] = React.useState(false);


	useEffect(() => {
		if (auth) {
			firebaseDb.child(`Timesheet/${formattedDate}/${auth.googleId}`).on('value', snapshot => {
				if (snapshot.val() != null) {
					setTimesheet(snapshot.val());
				}
			})
			firebaseDb.child(`ClassList`).on('value', snapshot => {
				if (snapshot.val() != null) {
					setClassesList(snapshot.val());
				}
			})
		}
	}, [])

	const save = () => {
		firebaseDb.child(`Timesheet/${formattedDate}/${auth.googleId}`).set(
			timesheet,
			err => {
				if (err)
					console.log(err)
			})
			setOpenAlert(true)
	}

	const handleChangeClass = (event,period) => {
		period.class=event.target.value;
		setUpdate(!update);
	};

	const handleChangeSubject = (event,period) => {
		period.subject=event.target.value;
		setUpdate(!update);
	};

	const handleChangeWorkdata = (event,period) => {
		period.workDetail=event.target.value;
		setUpdate(!update);
	};

	const handleAddRow = ()=>{
		timesheet.periods.push({
			workDetail: '',
			class: '',
			subject: ''
		})
		setUpdate(!update);
	}
	return (
		<>
		Date : {formattedDate}
			<Grid container spacing={2}>
				{timesheet.periods.map((period, index) => (
					<Grid item xs={12} md={12}>
						<TimesheetEntry
							period={period}
							classesList={classesList}
							handleChangeClass={handleChangeClass}
							handleChangeSubject={handleChangeSubject}
							handleChangeWorkdata={handleChangeWorkdata}
							row={index+1}
						/>
					</Grid>
				))}
			<Grid item xs={12}>
				<AddCircleOutlineIcon className="addicon" onClick={(event) => { handleAddRow()}}/>
			</Grid>
			<Grid item xs={12}>
				<Button variant="contained" color="primary" onClick={save} >Save </Button>
			</Grid>
			</Grid>
			<Snackbar open={openAlert} autoHideDuration={6000}>
        <MuiAlert elevation={6} variant="filled" onClose={()=>setOpenAlert(false)} severity="success">
          Changes saved successfully!
        </MuiAlert>
      </Snackbar>
		</>
	);
}

export default AddTimesheet