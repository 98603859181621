import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Tabs, Tab, TabPanel } from '@material-ui/core';
import firebaseDb from '../config/Firebase';
import { DateFinder } from '../utils/dateFinder';
import ReportAll from '../components/atoms/ReportAll';
import ReportByTeacher from '../components/atoms/ReportByTeacher';

const today = DateFinder();
const Report = () => {
    const [classList, setClassList] = useState([]);
    const [update, setUpdate] = useState(true);
     const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        firebaseDb.child(`ClassList`).on('value', snapshot => {
            if (snapshot.val() != null) {
                setClassList(snapshot.val());
                setUpdate(!update);
            }
        })
    }, [])

    return (
        <>
        <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Report All" />
                <Tab label="Report By Teacher"/>
            </Tabs>
        </AppBar>

        {value==0 ?
            <ReportAll classList={classList} index={0}/>
        :
        <ReportByTeacher classList={classList} index={1}/> 
        }
        
        
        
        </>
    );
}

export default Report;