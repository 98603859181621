import React, {useContext, useEffect, useState} from 'react';
import { useGoogleLogin } from 'react-google-login';
import firebaseDb from '../config/Firebase';
// refresh token
import { refreshTokenSetup } from '../utils/refreshToken';
import { AuthContext } from '../contexts/AuthProvider'
import { useHistory } from 'react-router-dom'
import { DateFinder } from '../utils/dateFinder';
import LogoutHooks from './LogoutHooks';

const clientId =
  '289619533297-p0dojgd41bm6fc77hl3ue7tj3rgkn11u.apps.googleusercontent.com';
function LoginHooks() {
  const { login } = useContext(AuthContext);
  const [user,setUser] = useState([]);
  const [loginUser,setLoginUser] = useState();
  const [update, setUpdate] = useState(true);
	const history = useHistory()
  const today=DateFinder();

  const onSuccess = (res) => {
    firebaseDb.child(`User/${res.profileObj.googleId}`).on('value', snapshot => {
      if (snapshot.val() != null) {      
        console.log(snapshot.val());
        login(snapshot.val());
        if(snapshot.val().isBlock==true){
          history.push('/block');
          <LogoutHooks />
        }

      }else{
        saveUser(res.profileObj);
      }
    })

		history.push('/dashboard')
    refreshTokenSetup(res);
  };

 const saveUser=(user)=>{
    user.isAdmin =false;
    user.isBlock=false;
    user.lastUpdate=null;
    user.lastLogin=today

    firebaseDb.child(`User/${user.googleId}`).set(
			user,
			err => {
				if (err)
					console.log(err)
			})
 }
  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
		<div className="google-btn"  onClick={signIn} >
			<div className="google-icon-wrapper">
				<img src="icons/google.svg" alt="google login" className="icon" height="40px"></img>
			</div>
			<p className="btn-text"><b>Sign in with google</b></p>
		</div>
	)
}

export default LoginHooks;