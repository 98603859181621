import React, {useContext} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClassIcon from '@material-ui/icons/Class';
import LogoutHooks from '../hooks/LogoutHooks'
import { AuthContext } from '../contexts/AuthProvider';
import { useHistory } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';

export const MainListItems = ()=>{
  const history = useHistory();
  	const { auth } = useContext(AuthContext);
  const handleNavigationClick = ()=>{
    history.push('/class');
  }
  const handleNavigationClick1 = ()=>{
    history.push('/dashboard');
  }
  const handleNavigationClick2 = ()=>{
    history.push('/report');
  }
  const handleNavigationClick3 = ()=>{
    history.push('/users');
  }

 return  (
  <div>
    {auth!=null ?
      auth.isAdmin==true ?
        <>
          <ListItem button onClick={handleNavigationClick1}>
      <ListItemIcon>
        <AccessTimeIcon />
      </ListItemIcon>
      <ListItemText primary="Timesheet" />
    </ListItem>
    <ListItem button onClick={handleNavigationClick}>
      <ListItemIcon >
        <ClassIcon />
      </ListItemIcon>
      <ListItemText primary="Class Data" />
    </ListItem>
    <ListItem button onClick={handleNavigationClick2}>
      <ListItemIcon>
        <AssessmentIcon />
      </ListItemIcon>
      <ListItemText primary="Report" />
    </ListItem>
    <ListItem button onClick={handleNavigationClick3}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
        </>
      :
      <ListItem button onClick={handleNavigationClick1}>
      <ListItemIcon>
        <AccessTimeIcon />
      </ListItemIcon>
      <ListItemText primary="Timesheet" />
    </ListItem>
    :
    <></>
    }
  </div>
);
 }

export const SecondaryListItems = () => {
	const { auth } = useContext(AuthContext);
	const history = useHistory();
	if(auth === null){
		history.push('/')
	}else if(auth!=null && auth.isBlock==true){
    alert("You are Blocked,Contact to admin");
    <LogoutHooks />
    history.push('/')
  }

	return (
  <div>
      <LogoutHooks />
  </div>
)};