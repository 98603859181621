import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, Paper, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DateFinder } from '../../utils/dateFinder';
import firebaseDb from '../../config/Firebase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import '../../App.css'
const useStyles = makeStyles((theme) => ({
    timesheetInput: {
        width: '100%',
    },

}))
const ReportAll = ((properties) => {
    const classes = useStyles();
    const { classList } = properties;
    const today = DateFinder()
    const [reportData, setReportData] = useState();
    const [reportDate, setReportDate] = useState(today);
    const [selectedClasses, setSelectClasses] = useState([]);
    const [update, setUpdate] = useState(false);
    const handleChangeReportDate = ((event) => {
        setReportDate(event.target.value);
        setUpdate(!update);
    })

    const reportMeta = {};
    const subjectMeta = {};
    // prepare meta for 2d array
    const classMeta = {};
    classList.forEach((classObj) => {
        reportMeta[classObj.name] = {}
        classMeta[classObj.name] = true;
        classObj.subjectList.forEach((subject) => {
            subjectMeta[subject] = ""
            reportMeta[classObj.name][subject] = null
        })
    })
    const SubjectArray = Object.keys(subjectMeta)
    useEffect(() => {
        firebaseDb.child(`Timesheet/${reportDate}`).on('value', snapshot => {
            if (snapshot.val() != null) {
                const allTimesheets = snapshot.val()
                Object.keys(allTimesheets).forEach((teacherData) => {
                    allTimesheets[teacherData].periods.forEach((period) => {
                        if (!reportMeta[period.class]) reportMeta[period.class] = {}
                        reportMeta[period.class][period.subject] = {
                            name: allTimesheets[teacherData].name,
                            work: period.workDetail
                        }
                    })
                })
                setReportData(reportMeta);
                setSelectClasses(classMeta);
                setUpdate(!update);
            } else {
                setReportData(reportMeta);
                setSelectClasses(classMeta);
                setUpdate(!update);
            }
        })
    }, [reportDate])

    const handleClassCheckbox = (event) => {
        console.log(event.target.value);
        if(event.target.value=="none"){
            classList.forEach((classObj)=>{
                selectedClasses[classObj.name]=!selectedClasses[classObj.name];
            })
            setUpdate(!update);
        }
        selectedClasses[event.target.value] = !selectedClasses[event.target.value];
        setUpdate(!update);
        console.log(event.target.value);
    }
    console.log(SubjectArray);
    console.log(selectedClasses);
    console.log(reportData);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        id="date"
                        label="Select Date"
                        type="date"
                        value={reportDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => { handleChangeReportDate(event) }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="demo-simple-select-label">Class</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={}
                        // onChange={(event) => { handleChangeClass(event, period) }}
                        className={classes.timesheetInput}
                    >
                        <div className="selectInput">
                        <Checkbox
                            color="default"
                            onChange={
                                (event) => {
                                    handleClassCheckbox(event);
                                }
                            }
                            value="none"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                        />
                        <MenuItem value="none">None</MenuItem>
                        </div>
                        {classList.map((classEntry) => {
                            return (
                                <div className="selectInput">
                                    {selectedClasses[classEntry.name] == true ?
                                        <>
                                            <Checkbox
                                                color="default"
                                                checked
                                                onChange={
                                                    (event) => {
                                                        handleClassCheckbox(event);
                                                    }
                                                }
                                                value={classEntry.name}
                                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            />
                                            <MenuItem value={classEntry.name}>{classEntry.name}</MenuItem>
                                        </>
                                        :
                                        <>
                                            <Checkbox
                                                color="default"
                                                onChange={
                                                    (event) => {
                                                        handleClassCheckbox(event);
                                                    }
                                                }
                                                value={classEntry.name}
                                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            />
                                            <MenuItem value={classEntry.name}>{classEntry.name}</MenuItem>
                                        </>
                                    }

                                </div>
                            )
                        })}
                    </Select>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Classes/Subjects</TableCell>
                            {SubjectArray.map((subject) =>
                                (<TableCell>{subject}</TableCell>)
                            )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classList ?
                            classList.map((classObj) => {
                                if (selectedClasses[classObj.name] == true) {
                                    return (
                                        <TableRow>
                                            <TableCell>{classObj.name}</TableCell>
                                            {SubjectArray.map((subject) =>
                                            (<TableCell>
                                                {reportData && reportData[classObj.name] && reportData[classObj.name][subject] ?
                                                    (<Tooltip title={reportData[classObj.name][subject]['name']}>
                                                        <span>{reportData[classObj.name][subject]['work']}</span>
                                                    </Tooltip>)
                                                    : ' '}
                                            </TableCell>)
                                            )
                                            }
                                        </TableRow>
                                    )
                                }
                            })
                            :
                            <div></div>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
})

export default ReportAll;